/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/popper.js@1.12.9/dist/umd/popper.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/moment@2.13.0/moment.min.js
 * - /npm/chart.js@2.7.3/dist/Chart.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
